import { BasePage } from '@components';
import { SMSChallengeCodeV2 } from '@fdha/web-auth';
import React from 'react';

export const SMSChallengeCode = () => {
  return (
    <BasePage
      type="default"
      contentSize="xsmall"
      verticallyCentered={true}
      headerProps={{
        title: 'Welcome',
        subtitle: 'Please enter your account information',
        showBack: false,
        showLogo: true,
        centeredHeader: true,
      }}
    >
      <SMSChallengeCodeV2 />
    </BasePage>
  );
};
