import React, { FC } from 'react';
import {
  Address,
  Caregiver,
  PhysicalActivityLevel,
  UserGender,
  useGetSupportedLanguagesQuery,
} from '@fdha/graphql-api-sitestaff';
import {
  AddressWarning,
  Icon,
  LabelBoxV2,
  Typography,
  formatUTCDate,
  maskPhone,
} from '@fdha/web-ui-library';
import {
  Box,
  Grid,
  Stack,
  useTheme,
  Paper as MuiPaper,
  styled,
  Tooltip,
} from '@mui/material';
import {
  userGenderLabelByValue,
  physicalActivityLabelByValue,
  languageInfoText,
} from '@utils';

export const Paper = styled(MuiPaper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  paddingRight: theme.spacing(4),
  paddingLeft: theme.spacing(4),
  rowGap: theme.spacing(3),
}));

interface ProfileInformationProps {
  isDraft?: boolean;
  isLoading: boolean;
  trialAbbreviation?: string | null;
  name?: string;
  firstName?: string;
  lastName?: string | null;
  subjectId?: string | null;
  birthdate?: string;
  weight?: number | null;
  height?: number | null;
  gender?: UserGender | null;
  physicalActivityLevel?: PhysicalActivityLevel | null;
  address?: Address | null;
  email?: string | null;
  emailConfirmation?: string | null;
  phoneNumber?: string | null;
  caregiver?: Caregiver | null;
  treatmentStartDate?: string;
  showTreatmentStartDate?: boolean;
  language?: string | null;
}

export const ProfileInformation: FC<ProfileInformationProps> = ({
  isDraft = false,
  isLoading,
  name,
  firstName,
  lastName,
  subjectId,
  birthdate,
  weight,
  height,
  gender,
  physicalActivityLevel,
  address,
  email,
  emailConfirmation,
  phoneNumber,
  caregiver,
  treatmentStartDate,
  showTreatmentStartDate,
  trialAbbreviation,
  language,
}) => {
  const theme = useTheme();

  const { data: languagesData } = useGetSupportedLanguagesQuery();
  const allSupportedLanguages = languagesData?.supportedLanguages || [];

  const isLarcTrial = trialAbbreviation === 'LARC';

  const getInfoLanguage = () => {
    return (
      <Tooltip
        data-testid="INFO_LANGUAGE_TOOLTIP"
        title={languageInfoText}
        arrow
      >
        <span>
          <Icon
            name="alert-circle-outline"
            fill={theme.palette.text.secondary}
            size="medium"
            showHover={true}
          />
        </span>
      </Tooltip>
    );
  };

  return (
    <Stack spacing={2}>
      <Paper>
        <Typography variant="h4" showSkeleton={isLoading}>
          Patient information
        </Typography>
        <Grid container columnSpacing={2} rowSpacing={3}>
          {isDraft ? (
            <>
              <LabelBoxV2
                showRequiredLabel={isDraft}
                label="First name"
                value={firstName}
                breakpoints={{ xs: 6, lg: 4 }}
                showSkeleton={isLoading}
              />
              <LabelBoxV2
                showRequiredLabel={isDraft}
                label="Last name"
                value={lastName}
                breakpoints={{ xs: 6, lg: 4 }}
                showSkeleton={isLoading}
              />
            </>
          ) : (
            <LabelBoxV2
              label="Name"
              value={name}
              breakpoints={{ xs: 12, lg: 8 }}
              showSkeleton={isLoading}
            />
          )}
          <LabelBoxV2
            showRequiredLabel={isDraft}
            label="Subject ID number"
            value={subjectId}
            breakpoints={{ xs: 6, lg: 4 }}
            showSkeleton={isLoading}
          />

          <LabelBoxV2
            showRequiredLabel={isDraft}
            label="Birth date"
            value={formatUTCDate(birthdate)}
            breakpoints={{ xs: 6, lg: 4 }}
            showSkeleton={isLoading}
          />
          <LabelBoxV2
            showRequiredLabel={isDraft}
            label="Weight"
            value={weight ? `${weight} kg` : undefined}
            breakpoints={{ xs: 6, lg: 4 }}
            showSkeleton={isLoading}
          />
          <LabelBoxV2
            showRequiredLabel={isDraft}
            label="Height"
            value={height ? `${height} cm` : undefined}
            breakpoints={{ xs: 6, lg: 4 }}
            showSkeleton={isLoading}
          />
          <LabelBoxV2
            showRequiredLabel={isDraft}
            label="Assigned sex at birth"
            value={gender ? userGenderLabelByValue[gender] : undefined}
            breakpoints={
              showTreatmentStartDate
                ? { xs: 6, lg: 4 }
                : { xs: 12, lg: 4, md: 6 }
            }
            showSkeleton={isLoading}
          />
          <LabelBoxV2
            showRequiredLabel={isDraft}
            label="Physical activity level"
            value={
              physicalActivityLevel
                ? physicalActivityLabelByValue[physicalActivityLevel]
                : undefined
            }
            breakpoints={
              showTreatmentStartDate
                ? { xs: 6, lg: 4 }
                : { xs: 12, lg: 8, md: 6 }
            }
            showSkeleton={isLoading}
          />
          {showTreatmentStartDate && (
            <LabelBoxV2
              showRequiredLabel={isDraft}
              label={isLarcTrial ? 'Radiotherapy start date' : 'C1D1 date'}
              breakpoints={{ xs: 6, lg: 4 }}
              showSkeleton={isLoading}
              value={formatUTCDate(treatmentStartDate)}
            />
          )}
          <LabelBoxV2
            showRequiredLabel={isDraft}
            label="Preferred language"
            value={
              allSupportedLanguages.find((l) => l.code === language)
                ?.nameEnglish
            }
            breakpoints={{ xs: 6, lg: 4 }}
            showSkeleton={isLoading}
            endAdornment={getInfoLanguage()}
          />
        </Grid>
      </Paper>
      <Paper>
        <Box>
          <Typography variant="h4" showSkeleton={isLoading}>
            Contact information
          </Typography>
          <Typography
            variant="body1"
            mt={1}
            color={theme.palette.text.secondary}
            showSkeleton={isLoading}
          >
            We will use this information to send all trial-related deliveries.
          </Typography>
        </Box>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <LabelBoxV2
            showRequiredLabel={isDraft}
            label="Address"
            value={address?.formatted}
            breakpoints={{ xs: 12, lg: 8 }}
            showSkeleton={isLoading}
          />
          <LabelBoxV2
            label="Apt, Suite, etc"
            value={address?.complement}
            breakpoints={{ xs: 12, lg: 4 }}
            showSkeleton={isLoading}
          />
          {isDraft && (
            <AddressWarning status={address?.addressValidationStatus} />
          )}
          <LabelBoxV2
            showRequiredLabel={isDraft}
            label="Email address"
            value={email}
            breakpoints={{ xs: 6, lg: 4 }}
            showSkeleton={isLoading}
          />
          {isDraft && (
            <LabelBoxV2
              showRequiredLabel
              label="Confirm email address"
              value={emailConfirmation}
              breakpoints={{ xs: 6, lg: 4 }}
              showSkeleton={isLoading}
            />
          )}
          <LabelBoxV2
            showRequiredLabel={isDraft}
            label="Mobile phone number"
            value={maskPhone(phoneNumber)}
            breakpoints={{ xs: 6, lg: 4 }}
            showSkeleton={isLoading}
          />
          <Grid
            item
            xs={12}
            display="flex"
            flexDirection="row"
            alignItems="end"
            my={1}
          >
            <Typography variant="h5" showSkeleton={isLoading}>
              Secondary contact information
            </Typography>
            <Typography
              variant="h6"
              color={theme.palette.text.hint}
              showSkeleton={isLoading}
              sx={{ fontStyle: 'italic', ml: 1 }}
            >
              (optional)
            </Typography>
          </Grid>
          <LabelBoxV2
            label="Contact name"
            value={caregiver?.name}
            breakpoints={{ xs: 6, lg: 8 }}
            showSkeleton={isLoading}
          />
          <LabelBoxV2
            label="Contact email address"
            value={caregiver?.primary_caregiver_email}
            breakpoints={{ xs: 6, lg: 4 }}
            showSkeleton={isLoading}
          />
        </Grid>
      </Paper>
    </Stack>
  );
};
