import {
  Badge,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import React, { useMemo, useState } from 'react';

import { Icon } from '../Icon/Icon';
import { SingleBadge } from '../SingleBadge/SingleBadge';
import { Button } from '../Button/Button';
import { Typography } from '../Typography/Typography';

export interface DrawerOption {
  value: string;
  label: string;
  activeIcon: string;
  inactiveIcon: string;
  notificationCount?: number;
  i18nKeyLabel?: string;
  onClick: (value: string) => void;
}

export interface DrawerMenuProps {
  buttonText?: string;
  i18nKey?: string;
  icon?: string;
  logoUrl: string;
  activeOption?: string;
  options: DrawerOption[];
}

export const DrawerMenu: React.FC<DrawerMenuProps> = ({
  buttonText,
  i18nKey,
  icon = 'menu-outline',
  logoUrl,
  activeOption,
  options,
}) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const theme = useTheme();

  const totalNotificationCount = useMemo(
    () => options.reduce((acc, opt) => acc + (opt.notificationCount || 0), 0),
    [options]
  );

  const renderItem = (item: DrawerOption) => {
    const isActive = activeOption === item.value;

    return (
      <ListItem key={item.label} disablePadding>
        <ListItemButton
          sx={{
            px: 0,
            py: 2,
            paddingLeft: 3,
            height: 60,
            display: 'flex',
          }}
          onClick={() => {
            item.onClick(item.value);
            setOpenDrawer(false);
          }}
        >
          <Box display="flex" alignItems="center">
            <ListItemIcon
              sx={{
                minWidth: 0,
                marginRight: 2,
              }}
            >
              <Icon
                name={isActive ? item.activeIcon : item.inactiveIcon}
                size="large"
                fill={grey[900]}
              />
            </ListItemIcon>
            <Box height="100%" display="flex" alignItems="center">
              <Typography
                i18nKey={item.i18nKeyLabel}
                fontWeight={isActive ? 'bold' : undefined}
              >
                {item.label}
              </Typography>
              <SingleBadge
                key={item.value}
                color="error"
                badgeContent={item.notificationCount}
                sx={{ marginLeft: 2 }}
              />
            </Box>
          </Box>
        </ListItemButton>
      </ListItem>
    );
  };

  return (
    <Box>
      <Badge color="error" badgeContent={totalNotificationCount}>
        <Button
          onClick={() => setOpenDrawer(true)}
          sx={{
            textTransform: 'none',
          }}
          startEvaIcon={{
            name: icon,
            size: 'large',
          }}
          color="secondary"
        >
          <Typography fontWeight="bold" i18nKey={i18nKey}>
            {buttonText}
          </Typography>
        </Button>
      </Badge>
      <Drawer
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          style: {
            width: '320px',
            boxShadow: theme.shadows[3],
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          paddingTop={4}
          data-testid="DRAWER_CONTENT"
        >
          <Box
            display="flex"
            justifyContent="center"
            borderBottom="1px solid"
            borderColor={grey[300]}
            paddingBottom={2}
          >
            <img src={logoUrl} alt="Faeth Logo" />
          </Box>
          <List sx={{ width: '100%', alignItems: 'center' }} disablePadding>
            {options.map((item) => renderItem(item))}
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};
