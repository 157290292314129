import React from 'react';
import { Box, List as MuiList, ListItem } from '@mui/material';

import { Loader } from '..';

export interface ScrollListProps<T> {
  items: T[];
  isLoading: boolean;
  disableRowGap?: boolean;
  children?: React.ReactNode;
  renderEmptyState?: () => React.ReactNode;
  renderLoading?: () => React.ReactNode;
  renderItem: (item: T, index: number) => React.ReactNode;
}

export function List<T>({
  items,
  isLoading,
  disableRowGap,
  children,
  renderItem,
  renderEmptyState,
  renderLoading,
}: ScrollListProps<T>) {
  if (isLoading) {
    if (renderLoading) {
      return <>{renderLoading()}</>;
    } else {
      return (
        <Box padding={1}>
          <Loader size={20} />
        </Box>
      );
    }
  }

  return (
    <MuiList
      disablePadding
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: disableRowGap ? 0 : 2,
      }}
    >
      {!items.length
        ? renderEmptyState && renderEmptyState()
        : items.map((item, index) => (
            <ListItem key={index} disablePadding>
              <Box flex="1 1 auto" minWidth={0}>
                {renderItem(item, index)}
              </Box>
            </ListItem>
          ))}
      {children}
    </MuiList>
  );
}
