import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { BasePage } from '@components';
import { useGetTrialQuery } from '@fdha/graphql-api-sitestaff';

import { AddOrEditSubject } from './AddOrEditSubject';

export const AddSubject = () => {
  const params = useParams();
  const { trialId } = params;

  const navigate = useNavigate();

  const { data: trialData, loading: loadingTrial } = useGetTrialQuery({
    variables: { id: trialId || '' },
  });

  return (
    <BasePage
      type="default"
      isLoading={loadingTrial}
      headerProps={{
        title: `Add new ${trialData?.trial.protocol_number} subject`,
        subtitle:
          '* Required field to submit profile. You can save a profile as a draft at any time.',
        showBack: false,
      }}
      navigationProps={{ type: 'breadcrumb' }}
    >
      <AddOrEditSubject
        trialId={trialData?.trial.id || ''}
        onCancel={() => navigate('../')}
      />
    </BasePage>
  );
};
