import React, { useEffect, useRef, useState } from 'react';
import {
  Avatar as MuiAvatar,
  AvatarProps as MuiAvatarProps,
  Badge,
  Box,
  styled,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';

import { VariantType } from '../../types/mui';
import { OverflowText } from '../OverflowText/OverflowText';
import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';
import { ButtonUnstyled } from '../Button/ButtonUnstyled';
import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

interface IconProps {
  name: string;
  fill?: string;
}

export type AvatarProps = Omit<MuiAvatarProps, 'onClick'> & {
  picture?: string | null;
  name?: string;
  presence?: 'online' | 'offline';
  size?: number;
  type?: 'imageOnly' | 'imageText' | 'iconText' | 'iconOnly';
  textPosition?: 'right' | 'left';
  testId?: string;
  textVariant?: VariantType;
  icon?: IconProps;
  downloadPicture?: (picture: string) => Promise<string | undefined>;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const Avatar = ({
  testId,
  textPosition = 'right',
  type = 'imageOnly',
  picture,
  presence,
  size = 40,
  downloadPicture,
  onClick,
  textVariant = 'body1',
  icon,
  ...props
}: AvatarProps) => {
  const isMounted = useRef(false);
  const theme = useTheme();
  const [avatarPicture, setAvatarPicture] = useState<string | undefined | null>(
    undefined
  );

  const showIcon = type === 'iconOnly' || type === 'iconText';
  const isIconText = type === 'iconText';
  const showName = type === 'imageText' || isIconText;

  const getInitials = () => {
    if (props.name) {
      const initials = props.name
        .replace(/[^\w\s]/g, '')
        .match(/(^\S\S?|\b\S)?/g)
        ?.join('')
        .match(/(^\S|\S$)?/g)
        ?.join('')
        .toUpperCase();

      return initials || '';
    }
  };

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (downloadPicture && picture) {
      downloadPicture(picture).then((downloadedPicture) => {
        if (isMounted.current) {
          setAvatarPicture(downloadedPicture);
        }
      });
    } else {
      setAvatarPicture(picture);
    }
  }, [picture, downloadPicture]);

  const renderAvatar = () => {
    const sx = { fontSize: size / 2, height: size, width: size, ...props.sx };
    const canShowPicture = avatarPicture && !showIcon;

    return canShowPicture ? (
      <MuiAvatar {...props} src={avatarPicture} sx={sx} />
    ) : showIcon && icon ? (
      <MuiAvatar {...props} sx={{ bgcolor: grey[300], ...props.sx }}>
        <Icon
          name={icon.name}
          size="large"
          fill={icon.fill || theme.palette.primary.main}
        />
      </MuiAvatar>
    ) : (
      <MuiAvatar {...props} sx={{ ...sx, bgcolor: theme.palette.primary.main }}>
        {getInitials()}
      </MuiAvatar>
    );
  };

  const renderAvatarWithPresence = () => {
    const PresenceDot = styled(Badge)(({ theme }) => ({
      '& .MuiBadge-badge': {
        backgroundColor:
          presence === 'online' ? theme.palette.success.main : grey[500],
        boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
        zIndex: 0,
      },
    }));

    return (
      <PresenceDot
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        badgeContent=""
        overlap="circular"
        variant="dot"
        data-testid="AVATAR_WITH_PRESENCE"
      >
        {renderAvatar()}
      </PresenceDot>
    );
  };

  return (
    <Box data-testid={testId} display="flex" alignItems="center">
      <ConditionalWrapper
        showWrapper={!!onClick}
        wrapper={(children) => (
          <ButtonUnstyled
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={(event) => onClick && onClick(event)}
          >
            {children}
          </ButtonUnstyled>
        )}
      >
        {showName && textPosition === 'left' && (
          <Typography variant={textVariant}>{props.name}</Typography>
        )}
        {presence != null ? renderAvatarWithPresence() : renderAvatar()}
        {showName && textPosition === 'right' && (
          <OverflowText label={props.name || ''} variant={textVariant} ml={2}>
            {props.name}
          </OverflowText>
        )}
      </ConditionalWrapper>
    </Box>
  );
};
