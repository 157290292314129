import React from 'react';
import { Badge as MuiBadge, BadgeProps as MuiBadgeProps } from '@mui/material';

type SingleBadgeProps = Pick<
  MuiBadgeProps,
  'badgeContent' | 'color' | 'max' | 'showZero' | 'sx'
>;

export const SingleBadge: React.VFC<SingleBadgeProps> = (props) => {
  return (
    <MuiBadge
      max={99}
      {...props}
      badgeContent={props.badgeContent || 0}
      sx={{
        '& .MuiBadge-badge:not(.MuiBadge-invisible)': {
          position: 'static',
          transform: 'none',
        },
        ...props.sx,
      }}
    />
  );
};
