import { useNavigate, useParams } from 'react-router';
import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { NetworkStatus } from '@apollo/client';
import {
  DraftFillingStatus,
  SiteStatus,
  SubjectResult,
  SubjectStatus,
  TrialStatus,
  useGetProfileQuery,
  useGetTrialQuery,
  useListTrialSubjectsQuery,
} from '@fdha/graphql-api-sitestaff';
import {
  Button,
  EmptyStateCard,
  Icon,
  InfiniteScrollList,
} from '@fdha/web-ui-library';
import {
  BasePage,
  PendingActionItem,
  PendingActions,
  SubjectTrialStatusChip,
  TrialChip,
} from '@components';
import { isMissingOnboardingCall } from '@fdha/common-utils';
import React, { useMemo } from 'react';

import LoadingState from './LoadingState';

export const Trial = () => {
  const theme = useTheme();
  const params = useParams();
  const { trialId = '' } = params;

  const navigate = useNavigate();

  const { data: profileData, loading: loadingProfile } = useGetProfileQuery();

  const { data: trialData, loading: loadingTrial } = useGetTrialQuery({
    variables: { id: trialId },
  });

  const {
    data: subjectsData,
    networkStatus,
    fetchMore,
  } = useListTrialSubjectsQuery({
    variables: { trialId: trialId || '' },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true,
  });
  const subjects =
    subjectsData?.trialSubjects.edges.map((subject) => subject.node) || [];
  const pageInfo = subjectsData?.trialSubjects.pageInfo;

  const isLoading = networkStatus === NetworkStatus.loading;
  const isLoadingMore = networkStatus === NetworkStatus.fetchMore;
  const hasMoreResults = pageInfo?.hasNextPage;

  const siteTrial = useMemo(
    () =>
      profileData?.me.site.siteTrials?.find((st) => st.trial?.id === trialId),
    [profileData?.me.site.siteTrials, trialId]
  );

  const loadMore = () => {
    if (!hasMoreResults || isLoadingMore) {
      return;
    }

    fetchMore({
      variables: {
        trialId: trialId || '',
        after: pageInfo?.endCursor,
      },
    });
  };

  const showAddSubjectButton = useMemo(
    () =>
      trialData?.trial.status === TrialStatus.Recruiting &&
      siteTrial?.status === SiteStatus.Active,
    [trialData?.trial.status, siteTrial?.status]
  );

  const onItemClick = (routeId?: string) => {
    navigate(`./${routeId}`);
  };

  const renderRightButton = () => {
    return showAddSubjectButton ? (
      <Button
        data-testid="ADD_NEW_SUBJECT_BUTTON"
        variant="contained"
        onClick={() => navigate(`./add-subject`)}
      >
        Add new subject
      </Button>
    ) : undefined;
  };

  const getSubjectTrialStatusChip = (status?: SubjectStatus | null) => {
    return <SubjectTrialStatusChip subjectStatus={status} />;
  };
  const getTrialChip = (status?: TrialStatus | null) => {
    return (
      status && (
        <TrialChip trialStatus={status} sx={{ width: '100%' }} size="medium" />
      )
    );
  };

  const getSubtitle = (status?: TrialStatus | null) => {
    switch (status) {
      case TrialStatus.Completed:
        return 'This clinical trial has already been completed.';
      case TrialStatus.ActiveNotRecruiting:
        return 'This trial is no longer enrolling subjects.';
      default:
        return undefined;
    }
  };

  const getPendingActionItems = (subject: SubjectResult) => {
    const pendingActionItems: PendingActionItem[] = [];

    if (subject.status === SubjectStatus.Draft) {
      const isDraftFillingComplete =
        subject.fillingStatus === DraftFillingStatus.Complete;

      pendingActionItems.push({
        type: isDraftFillingComplete
          ? 'PendingProfileSubmission'
          : 'PendingProfileFilling',
      });
    }

    if (
      isMissingOnboardingCall(
        subject.status,
        subject.onboardingCallNeeded,
        subject.onboardingCallScheduled
      )
    ) {
      pendingActionItems.push({
        type: 'PendingOnboardingCall',
      });
    }

    return pendingActionItems;
  };

  const renderItem = (subject: SubjectResult) => {
    const routeId =
      subject.status === SubjectStatus.Draft
        ? subject.subjectTrialId
        : subject.patientId;

    return (
      <Card data-testid="TRIAL_SUBJECT_CARD">
        <CardActionArea onClick={() => onItemClick(routeId ?? undefined)}>
          <CardContent>
            <Stack spacing={0.5}>
              <Stack direction="row" justifyContent="space-between">
                <Stack spacing={0.5} direction="row" alignItems="center">
                  <Icon
                    name="person-outline"
                    size="large"
                    fill={theme.palette.text.secondary}
                  />
                  <Typography variant="h6" fontWeight="bold">
                    {subject.name}
                  </Typography>
                </Stack>
                {getSubjectTrialStatusChip(subject.status)}
              </Stack>
              <Typography
                data-testid="TRIAL_SUBJECT_ID_CARD"
                variant="subtitle1"
                color={theme.palette.text.secondary}
              >
                {subject.subjectId}
              </Typography>
            </Stack>
            <PendingActions
              mode="inline"
              pendingActionItems={getPendingActionItems(subject)}
              sx={{ marginTop: 2 }}
            />
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  const clockOutlineIcon = (
    <Icon
      name="clock-outline"
      size="large"
      fill={theme.palette.text.secondary}
    />
  );

  const renderEmptyState = (status?: TrialStatus | null) => {
    const emptyState =
      status && status === TrialStatus.NotYetRecruiting ? (
        <EmptyStateCard
          iconType="custom"
          customIcon={clockOutlineIcon}
          message={
            'The enrolling phase for this trial will begin soon.' +
            '\nPlease stay tuned for updates and announcements on the starting date.'
          }
        />
      ) : (
        <EmptyStateCard
          iconType="default"
          message="There are no subjects to display at the moment."
        />
      );
    return emptyState;
  };

  const renderLoading = () => {
    return <LoadingState />;
  };

  return (
    <BasePage
      type="default"
      isLoading={loadingTrial || loadingProfile}
      headerProps={{
        title: trialData?.trial?.protocol_number,
        rightButton: renderRightButton(),
        subtitle: getSubtitle(trialData?.trial.status),
      }}
      navigationProps={{ type: 'breadcrumb' }}
    >
      <Stack spacing={2}>
        {getTrialChip(trialData?.trial.status)}
        <InfiniteScrollList
          items={subjects}
          isLoading={isLoading}
          isLoadingMore={isLoadingMore}
          loadMore={loadMore}
          renderItem={renderItem}
          renderEmptyState={() => renderEmptyState(trialData?.trial.status)}
          renderLoading={renderLoading}
        />
      </Stack>
    </BasePage>
  );
};
