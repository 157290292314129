import React from 'react';
import { TrialStatus } from '@fdha/graphql-api-sitestaff';
import { Chip, ChipProps } from '@fdha/web-ui-library';

interface TrialChipProps extends ChipProps {
  trialStatus: TrialStatus;
}

const trialStatusLabelByValue: Record<TrialStatus, string> = {
  [TrialStatus.Completed]: 'Completed',
  [TrialStatus.Recruiting]: 'Active - Enrolling',
  [TrialStatus.NotYetRecruiting]: 'Active - Not yet enrolling',
  [TrialStatus.Terminated]: 'Terminated',
  [TrialStatus.ActiveNotRecruiting]: 'Active - Not enrolling',
};

const trialStatusColorByValue = (trialStatus: TrialStatus) => {
  switch (trialStatus) {
    case TrialStatus.Completed:
      return 'secondary';
    case TrialStatus.Recruiting:
      return 'success';
    case TrialStatus.NotYetRecruiting:
      return 'info';
    case TrialStatus.Terminated:
      return 'error';
    case TrialStatus.ActiveNotRecruiting:
      return 'info';
  }
};

const iconName: Record<TrialStatus, string> = {
  [TrialStatus.Completed]: 'done-all-outline',
  [TrialStatus.Recruiting]: 'checkmark-outline',
  [TrialStatus.NotYetRecruiting]: 'checkmark-circle-2-outline',
  [TrialStatus.Terminated]: 'close-circle-outline',
  [TrialStatus.ActiveNotRecruiting]: 'checkmark-circle-2-outline',
};

export const TrialChip: React.FC<TrialChipProps> = ({
  trialStatus,
  ...props
}) => {
  return (
    <Chip
      color={trialStatusColorByValue(trialStatus)}
      label={trialStatusLabelByValue[trialStatus]}
      data-testid="CHIP"
      variant="outlined"
      icon={{ name: iconName[trialStatus] }}
      {...props}
    />
  );
};
