import React, { FC, useMemo } from 'react';
import { Typography, Icon } from '@fdha/web-ui-library';
import { Box, Stack, useTheme } from '@mui/material';
import { format, parseISO } from 'date-fns';

interface OnboardingCallInfoProps {
  datetime: string;
  type: string;
  callDuration: number;
  isLoading: boolean;
}

export const OnboardingCallInfo: FC<OnboardingCallInfoProps> = ({
  datetime,
  type,
  callDuration,
  isLoading,
}) => {
  const theme = useTheme();

  const duration = useMemo(() => {
    return {
      hours: Math.floor(callDuration / 60),
      minutes: callDuration % 60,
    };
  }, [callDuration]);

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent="space-between"
      spacing={1}
      py={3}
      px={4}
      borderRadius={1}
      sx={{
        backgroundColor: theme.palette.background.bright1,
      }}
    >
      <Stack spacing={1} direction="row" alignItems="center">
        <Icon
          name="calendar-outline"
          size="large"
          fill={theme.palette.text.primary}
          showSkeleton={isLoading}
        />
        <Typography variant="h5" fontWeight="bold" showSkeleton={isLoading}>
          {type}
        </Typography>
      </Stack>
      <Box textAlign={{ xs: 'left', md: 'right' }}>
        <Typography
          data-testid="ONBOARDING_CALL_INFO"
          variant="body1"
          showSkeleton={isLoading}
        >
          {format(parseISO(datetime), "LLL d, y 'at' K:mm bb")}
        </Typography>
        <Typography
          data-testid="ONBOARDING_CALL_INFO_TIME"
          variant="body1"
          color={theme.palette.text.hint}
          showSkeleton={isLoading}
        >
          {!!duration.hours
            ? `${duration.hours}h:${duration.minutes}m`
            : `${duration.minutes} minutes`}
        </Typography>
      </Box>
    </Stack>
  );
};
