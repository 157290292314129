import React from 'react';
import { SplashLoader } from '@components';
import { useSystemSetting } from '@fdha/common-hooks';
import {
  ChangePassword,
  ForgotPassword,
  Login,
  ResetPassword,
  SMSChallengeCode,
} from '@pages';
import { Route, Routes } from 'react-router-dom';

export const publicPageNamesByRoute = [
  { route: '/', name: 'Login' },
  { route: '/change-password', name: 'Change Password' },
  { route: '/forgot-password', name: 'Forgot Password' },
  { route: '/reset-password', name: 'Reset Password' },
  { route: '/sms-challenge', name: 'SMS Challenge' },
];

export const Public = () => {
  const { value: termsOfServiceLink, isLoading: loadingSystemSettingTerms } =
    useSystemSetting('termsOfServiceLink', { allowAutomaticRefetch: false });

  const { value: privacyPolicyLink, isLoading: loadingSystemSettingPrivacy } =
    useSystemSetting('privacyPolicyLink', { allowAutomaticRefetch: false });

  if (loadingSystemSettingTerms || loadingSystemSettingPrivacy) {
    return <SplashLoader />;
  }

  return (
    <Routes>
      <Route path="*" element={<Login />}></Route>
      <Route
        path="/change-password"
        element={
          <ChangePassword
            termsOfServiceLink={termsOfServiceLink}
            privacyPolicyLink={privacyPolicyLink}
          />
        }
      />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/sms-challenge" element={<SMSChallengeCode />} />
    </Routes>
  );
};
