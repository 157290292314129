import { Box } from '@mui/material';
import React from 'react';

export const SplashLoader = () => {
  return (
    <Box
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <img src="/images/studyhub-logo.png" alt="Study Hub Logo" />
    </Box>
  );
};
