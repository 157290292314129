"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unreachableLog = exports.unreachableThrow = void 0;
var unreachableThrow = function (x) {
    throw new Error("Unreachable case: ".concat(x));
};
exports.unreachableThrow = unreachableThrow;
var unreachableLog = function (x) {
    console.error("Unreachable case: ".concat(x));
};
exports.unreachableLog = unreachableLog;
