import React from 'react';
import { BasePage } from '@components';
import { ProfileChangePasswordV2 } from '@fdha/web-auth';

interface ProfileChangePasswordProps {
  email?: string;
}

export const ProfileChangePassword: React.FC<ProfileChangePasswordProps> = ({
  email,
}) => {
  return (
    <BasePage
      type="default"
      contentSize="small"
      headerProps={{
        title: 'Change Password',
        showBack: true,
      }}
    >
      <ProfileChangePasswordV2 email={email} hideLogoutModal />
    </BasePage>
  );
};
