import { AuthStatus, useAuthStatus } from '@fdha/web-auth';
import {
  BasePageV2,
  BasePageV2Body,
  BasePageV2Header,
  BasePageV2HeaderProps,
  BasePageV2Navigation,
  BasePageV2NavigationProps,
  BasePageV2Props,
  BasePageV2Type,
  NavigationCloseAction,
} from '@fdha/web-ui-library';
import { privatePageNamesByRoute, publicPageNamesByRoute } from '@routes';
import React, { PropsWithChildren, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { onError } from '@apollo/client/link/error';
import { useApolloClient } from '@apollo/client';

type BasePageHeaderProps = Omit<
  BasePageV2HeaderProps,
  'isLoading' | 'onClickBack' | 'logoUrl'
> & { showLogo?: boolean };

interface NavigationPropsBase {
  type: BasePageV2NavigationProps['type'];
  progress?: BasePageV2NavigationProps['progress'];
}

interface CloseNavigationProps extends NavigationPropsBase {
  type: 'close';
  onBeforeClose?: () => Promise<NavigationCloseAction>;
}

interface BreadcrumbNavigationProps extends NavigationPropsBase {
  type: 'breadcrumb';
  currentRouteName?: string;
}

export type BasePageNavigationProps =
  | CloseNavigationProps
  | BreadcrumbNavigationProps;

interface BasePagePropsBase {
  contentSize?: BasePageV2Props['contentSize'];
  type: BasePageV2Type;
  isLoading?: boolean;
  headerProps?: BasePageHeaderProps;
  navigationProps?: BasePageNavigationProps;
}

interface BasePageDefaultProps extends BasePagePropsBase {
  type: 'default';
  verticallyCentered?: boolean;
}

interface BasePageCoverProps extends BasePagePropsBase {
  type: 'cover';
  coverImageUrl: string;
}

interface BasePageV2NotFoundProps extends BasePagePropsBase {
  type: 'notFound';
}

export type BasePageProps =
  | BasePageDefaultProps
  | BasePageCoverProps
  | BasePageV2NotFoundProps;

export const BasePage: React.FC<PropsWithChildren<BasePageProps>> = ({
  children,
  contentSize = 'xlarge',
  isLoading,
  headerProps,
  navigationProps,
  ...props
}) => {
  const authStatus = useAuthStatus();
  const navigate = useNavigate();
  const location = useLocation();
  const client = useApolloClient();
  const [isNotFound, setIsNotFound] = useState<boolean>(false);

  const pageNamesByRoute = useMemo(() => {
    if (authStatus === AuthStatus.SIGNED_IN) {
      return privatePageNamesByRoute;
    }
    return publicPageNamesByRoute;
  }, [authStatus]);

  const handleBack = () => {
    navigate('../');
  };

  const renderNavigation = () => {
    if (!navigationProps) {
      return null;
    }

    if (navigationProps.type === 'close') {
      return (
        <BasePageV2Navigation
          type="close"
          progress={navigationProps.progress}
          isLoading={isLoading}
          closeProps={{
            onBeforeClose: navigationProps.onBeforeClose,
            onClose: handleBack,
          }}
        />
      );
    } else if (navigationProps.type === 'breadcrumb') {
      return (
        <BasePageV2Navigation
          type="breadcrumb"
          progress={navigationProps.progress}
          isLoading={isLoading}
          breadcrumbProps={{
            currentRouteName: { text: navigationProps.currentRouteName },
            pageNamesByRoute: pageNamesByRoute,
            pathname: location.pathname,
            onClick: (route) => navigate(route),
            hasCustomHome: true,
          }}
        />
      );
    }
  };

  const errorControl = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (!extensions.extensions) {
          return;
        }
        const codeExtensions = extensions.extensions as { code?: string };

        if (codeExtensions.code === 'NOT_FOUND') {
          setIsNotFound(true);
        }
      });
    }
  });

  client.setLink(errorControl.concat(client.link));

  const notFoundRedirect = () => navigate('/');

  return (
    <BasePageV2
      type={isNotFound ? 'notFound' : props.type}
      contentSize={contentSize}
      coverImageUrl={props.type === 'cover' ? props.coverImageUrl : undefined}
      verticallyCentered={
        props.type === 'default' ? props.verticallyCentered : undefined
      }
      handleNotFoundRedirect={notFoundRedirect}
    >
      {renderNavigation()}
      {headerProps && (
        <BasePageV2Header
          {...headerProps}
          logoUrl={
            headerProps.showLogo
              ? '/images/study_hub_logo_small.png'
              : undefined
          }
          isLoading={isLoading}
          onClickBack={handleBack}
        />
      )}
      <BasePageV2Body
        fullHeight={props.type === 'default' && !props.verticallyCentered}
      >
        {children}
      </BasePageV2Body>
    </BasePageV2>
  );
};
