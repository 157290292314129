import React, { FC } from 'react';
import { Grid, Paper, useTheme, Stack } from '@mui/material';

import { Icon } from '../Icon/Icon';
import { Typography } from '../Typography/Typography';

export interface WarningProps {
  title?: string;
  text?: string;
  content?: React.ReactNode;
}

export const Warning: FC<WarningProps> = ({ title, text, content }) => {
  const theme = useTheme();

  return text ? (
    <Grid item xs={12}>
      <Paper
        elevation={0}
        sx={{
          p: 2,
          backgroundColor: theme.palette.warning.lightBg,
          borderColor: theme.palette.warning.border,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          columnGap: 2,
        }}
      >
        <Stack spacing={2}>
          {title && (
            <Typography variant="h6" fontWeight="bold">
              {title}
            </Typography>
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            <Icon
              name="alert-triangle-outline"
              fill={theme.palette.warning.dark}
              size="large"
            />
            <Typography variant="body2" color={theme.palette.text.secondary}>
              {text}
            </Typography>
          </Stack>
          {content}
        </Stack>
      </Paper>
    </Grid>
  ) : null;
};
