import {
  differenceInYears,
  format,
  parseISO,
  startOfToday,
  isValid,
  parse,
  isPast,
} from 'date-fns';
import {
  format as formatTimezone,
  utcToZonedTime,
  zonedTimeToUtc,
} from 'date-fns-tz';

export type DateFormat =
  | 'monthDayYear'
  | 'monthYear'
  | 'monthDay'
  | 'shortMonth'
  | 'dayOfMonth'
  | 'monthWeekDay'
  | 'longMonthDayYear'
  | 'formalMonthDay'
  | 'weekDays';

export const getDateFormat = (dateFormat: DateFormat) => {
  switch (dateFormat) {
    case 'monthDayYear':
      return 'MM/dd/yyyy';
    case 'monthYear':
      return 'MM/yyyy';
    case 'monthDay':
      return 'MMMM dd';
    case 'dayOfMonth':
      return 'MMMM do';
    case 'shortMonth':
      return 'MMM dd';
    case 'monthWeekDay':
      return 'EEEE, MMMM dd';
    case 'longMonthDayYear':
      return 'MMMM d, yyyy';
    case 'formalMonthDay':
      return 'MM/dd';
    case 'weekDays':
      return 'EEE';
  }
};

/* Use when you care only about the date, but the data is a complete dateTime in ISO format */
const formatUTCDate = (
  ISODate?: string,
  _format: DateFormat = 'monthDayYear'
) => {
  if (ISODate) {
    const dateFormat = getDateFormat(_format);
    const date = utcToZonedTime(ISODate, 'UTC');
    return format(date, dateFormat);
  }
};

const formatDate = (
  date: Date | null,
  dateFormat: DateFormat = 'monthDayYear'
) => {
  return date ? format(date, getDateFormat(dateFormat)) : '';
};

const formatDateWithTimezone = (date: Date, timezone: string) => {
  return formatTimezone(date, 'MM/dd/yyyy - hh:mm aa zzz', {
    timeZone: timezone,
  });
};

const formatDateWithoutTimezone = (date: Date) => {
  return formatTimezone(date, 'MM/dd/yyyy - hh:mm aa');
};

type HourFormat = 'hour' | '12Hours' | '12HoursWithZero' | 'dayPeriod';
const getHourFormat = (dateFormat: HourFormat) => {
  switch (dateFormat) {
    case '12Hours':
      return 'h:mm aa';
    case '12HoursWithZero':
      return 'hh:mm aa';
    case 'dayPeriod':
      return 'aaa';
    case 'hour':
      return 'hh:mm';
  }
};

const formatHour = (
  ISODate?: string | Date,
  _format: HourFormat = '12Hours'
) => {
  if (ISODate) {
    const hourFormat = getHourFormat(_format);
    const date = typeof ISODate === 'string' ? parseISO(ISODate) : ISODate;
    return format(date, hourFormat);
  }
};

const formatHourUTC = (ISODate?: string, _format: HourFormat = '12Hours') => {
  if (!ISODate) return;

  const hourFormat = getHourFormat(_format);
  const date = utcToZonedTime(ISODate, 'UTC');
  return format(date, hourFormat);
};

// Returns date with time 0 in UTC timezone
const getTodayInUTC = () => {
  return zonedTimeToUtc(startOfToday(), 'UTC');
};

const getAge = (_date?: string | number | Date) => {
  if (_date) {
    const date = utcToZonedTime(_date, 'UTC');
    const today = zonedTimeToUtc(startOfToday(), 'UTC');

    const age = differenceInYears(today, date);
    return age.toString();
  }
};

const addDayToDateString = (date?: string) => {
  if (date) {
    return date.replace('/', '/01/');
  }
};

const isValidDate = (date?: string) => {
  return date && isValid(new Date(date)) && date.length === 10;
};

const buildDateTime = (date: string, time?: string, dayPeriod?: string) => {
  return parse(
    `${date} ${time} ${dayPeriod}`,
    'MM/dd/yyyy hh:mm a',
    new Date()
  ).toISOString();
};

const verifyFutureTime = (value: string, isPm?: boolean) => {
  const [hours, minutes] = value.split(':');
  const time = startOfToday();
  const shiftedHour = Number(hours) === 12 ? 0 : Number(hours);
  const formattedHour = isPm ? shiftedHour + 12 : shiftedHour;
  time.setHours(formattedHour, Number(minutes));
  return isPast(time);
};

const getTimezoneString = (timezone?: string) => {
  if (!timezone) {
    return;
  }

  const abbr = new Date()
    .toLocaleTimeString('en-us', {
      timeZone: timezone,
      timeZoneName: 'short',
    })
    .split(' ')[2];

  const long = new Date()
    .toLocaleDateString('en-us', {
      timeZone: timezone,
      timeZoneName: 'long',
    })
    .split(',')[1];
  const zonedTime = format(utcToZonedTime(new Date(), timezone), 'hh:mm aa');

  return `${abbr} - ${long} - ${zonedTime}`;
};

const buildDateUTCTime = (date: string, time: string, dayPeriod?: string) => {
  const [month, day, year] = date.split('/');
  const [hour, minute] = time?.split(':');
  const dateTimeString = `${month}/${day}/${year} ${hour}:${minute}:00 ${dayPeriod} UTC`;
  const utcDate = new Date(dateTimeString);
  return utcDate.toISOString();
};

export {
  formatUTCDate,
  formatDate,
  formatDateWithTimezone,
  formatHour,
  getAge,
  getTodayInUTC,
  addDayToDateString,
  isValidDate,
  buildDateTime,
  verifyFutureTime,
  getTimezoneString,
  buildDateUTCTime,
  formatDateWithoutTimezone,
  formatHourUTC,
};
