import { PhysicalActivityLevel, UserGender } from '@fdha/graphql-api-sitestaff';

import {
  physicalActivityLabelByValue,
  userGenderLabelByValue,
} from './labelByValueMappers';

export const physicalActivityOptions = Object.entries(
  PhysicalActivityLevel
).map(([key, value]) => ({
  label: physicalActivityLabelByValue[value],
  value: key,
}));

export const genderOptions = Object.entries(UserGender).map(([key, value]) => ({
  label: userGenderLabelByValue[value],
  value: key,
}));
