import { Box, styled, Typography, useTheme } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingBottom: theme.spacing(4),
}));

interface ProfileCardHeaderProps {
  title: string;
  subtitle?: string;
}

const ProfileCardHeader: FC<PropsWithChildren<ProfileCardHeaderProps>> = ({
  title,
  subtitle,
  children,
}) => {
  const theme = useTheme();

  return (
    <Header>
      {children}
      <Box>
        <Typography variant="h5" data-testid="PROFILE_NAME">
          {title}
        </Typography>
        {subtitle && (
          <Typography
            variant="body1"
            color={theme.palette.text.secondary}
            data-testid="PROFILE_SUBTITLE"
          >
            {subtitle}
          </Typography>
        )}
      </Box>
    </Header>
  );
};

export default ProfileCardHeader;
