import React, { FC } from 'react';
import { BasePage, TrialChip } from '@components';
import { Trial } from '@fdha/graphql-api-sitestaff';
import { EmptyStateCard, Icon, pluralize } from '@fdha/web-ui-library';
import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';

interface HomeProps {
  trials?: Trial[];
}

export const Home: FC<HomeProps> = ({ trials }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const getPatientsCount = (count?: number | null) => {
    if (count && count > 0) {
      return {
        color: theme.palette.primary.light,
        iconColor: theme.palette.primary.main,
        fontWeight: 'bold',
        text: `${count} active`,
      };
    } else {
      return {
        color: theme.palette.text.secondary,
        iconColor: grey[700],
        fontWeight: 'normal',
        text: 'no active',
      };
    }
  };

  const renderItem = (item: Trial) => {
    const warningMessageColor = theme.palette.warning.dark;
    const { color, iconColor, fontWeight, text } = getPatientsCount(
      item.number_of_patients
    );

    return (
      <Card sx={{ height: '100%' }} data-testid="HOME_CARD">
        <CardActionArea
          sx={{ height: '100%' }}
          onClick={() => navigate(`./${item.id}`)}
        >
          <CardContent sx={{ paddingX: 4, paddingY: 3, height: '100%' }}>
            <Stack spacing={2}>
              <Stack spacing={1} direction="row" alignItems="center">
                <Icon
                  data-testid="TRIAL_CARD_ICON"
                  name="file-text-outline"
                  size="xlarge"
                  fill={theme.palette.primary.main}
                />
                <Typography
                  data-testid="TRIAL_CARD_PROTOCOL_NUMBER"
                  variant="h4"
                >
                  {item.protocol_number}
                </Typography>
              </Stack>
              {item.status && (
                <TrialChip
                  data-testid="TRIAL_CARD_STATUS"
                  trialStatus={item.status}
                  size="small"
                />
              )}
              <Typography data-testid="TRIAL_CARD_DESCRIPTION">
                {item.description}
              </Typography>
              <Stack spacing={1} direction="row" alignItems="center">
                <Icon name="person-outline" size="large" fill={iconColor} />
                <Typography
                  variant="body2"
                  display="inline"
                  color={theme.palette.text.secondary}
                  component="div"
                  data-testid="TRIAL_CARD_PATIENTS_INFO"
                >
                  You have{' '}
                  <Typography
                    variant="body2"
                    display="inline"
                    fontWeight={fontWeight}
                    color={color}
                  >
                    {text}
                  </Typography>
                  {pluralize(
                    item.number_of_patients || 0,
                    'patient',
                    's',
                    true
                  )}{' '}
                  in this trial
                </Typography>
              </Stack>
              {!!item.missingOnboardingCallCount && (
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  data-testid="TRIAL_CARD_ONBOARDING_CALLS"
                >
                  <Icon
                    name="calendar-outline"
                    size="large"
                    fill={warningMessageColor}
                    sx={{ display: 'inline' }}
                  />
                  <Typography
                    variant="body2"
                    color={theme.palette.text.secondary}
                    component="div"
                  >
                    {item.missingOnboardingCallCount}
                    <Typography
                      variant="body2"
                      display="inline"
                      color={warningMessageColor}
                      fontWeight="bold"
                    >
                      {pluralize(
                        item.missingOnboardingCallCount,
                        'onboarding call',
                        's',
                        true
                      )}
                    </Typography>{' '}
                    to schedule.
                  </Typography>
                </Stack>
              )}
              {!!item.numberOfDrafts && (
                <Stack
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  data-testid="TRIAL_CARD_DRAFTS"
                >
                  <Icon
                    name="edit-2-outline"
                    size="large"
                    fill={warningMessageColor}
                    sx={{ display: 'inline' }}
                  />
                  <Typography
                    component="div"
                    variant="body2"
                    color={theme.palette.text.secondary}
                  >
                    {pluralize(item.numberOfDrafts, 'profile')}{' '}
                    {item.numberOfDrafts > 1 ? 'are' : 'is'} still in{' '}
                    <Typography
                      variant="body2"
                      display="inline"
                      fontWeight="bold"
                      color={warningMessageColor}
                    >
                      draft mode
                    </Typography>
                    .
                  </Typography>
                </Stack>
              )}
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  };

  return (
    <BasePage
      type="default"
      headerProps={{
        title: 'Available clinical trials',
        showBack: false,
        centeredHeader: true,
      }}
    >
      {!trials?.length ? (
        <EmptyStateCard
          message="Your site has no access to trials at this time."
          iconType="default"
        />
      ) : (
        <Grid container columnSpacing={3} justifyContent="center">
          {trials.map((item) => (
            <Grid item xs={4} key={item.id} display="flex">
              {renderItem(item)}
            </Grid>
          ))}
        </Grid>
      )}
    </BasePage>
  );
};
