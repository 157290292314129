import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { BasePage, SubjectTrialStatusChip } from '@components';
import { useGetSubjectDraftQuery } from '@fdha/graphql-api-sitestaff';
import { Stack } from '@mui/material';

import { AddOrEditSubject } from './AddOrEditSubject';

export const EditSubject = () => {
  const params = useParams();
  const { trialId, profileId } = params;

  const navigate = useNavigate();

  const { data, loading } = useGetSubjectDraftQuery({
    variables: { id: profileId || '' },
  });

  const title = `${data?.subjectDraft?.firstName} ${data?.subjectDraft?.lastName}`;

  return (
    <BasePage
      type="default"
      isLoading={loading}
      headerProps={{
        title,
        subtitle:
          '* Required field to submit profile. You can save a profile as a draft at any time.',
      }}
      navigationProps={{ type: 'breadcrumb' }}
    >
      <Stack spacing={2}>
        <SubjectTrialStatusChip
          subjectStatus={data?.subjectDraft.subjectTrialStatus}
          showSkeleton={loading}
          sx={{ width: '100%' }}
          size="medium"
        />
        <AddOrEditSubject
          trialId={trialId || ''}
          loading={loading}
          subject={data?.subjectDraft}
          onCancel={() => navigate('../')}
        />
      </Stack>
    </BasePage>
  );
};
