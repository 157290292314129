import { styled, Button } from '@mui/material';
import React from 'react';

import { Typography } from '../Typography/Typography';
import { Username } from '../Username/Username';

const UserBox = styled(Button)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  justifyContent: 'flex-start',
  padding: theme.spacing(2, 1),
  border: '1px solid',
  borderRadius: 15,
  borderColor: theme.palette.divider,
  textTransform: 'inherit',
}));

interface UserCardProps {
  name?: string;
  showUserType: boolean;
  handleClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function UserCard({ name, showUserType = true, handleClick }: UserCardProps) {
  return (
    <UserBox data-testid="USER_CARD" onClick={handleClick}>
      {showUserType && <Typography marginRight="5px">Coach</Typography>}
      <Username name={name || ''} />
    </UserBox>
  );
}

export default UserCard;
