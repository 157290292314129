import React from 'react';
import { useParams } from 'react-router';

import { PatientProfile } from './PatientProfile';
import { DraftProfile } from './DraftProfile';

export const SubjectProfile = () => {
  const params = useParams();

  const { profileId } = params;
  const isDraft = !profileId?.startsWith('pat_');

  return isDraft ? <DraftProfile /> : <PatientProfile />;
};
