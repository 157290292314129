import React from 'react';
import { BasePage } from '@components';
import { ForgotPasswordV2 } from '@fdha/web-auth';

export const ForgotPassword = () => {
  return (
    <BasePage
      type="default"
      contentSize="xsmall"
      verticallyCentered={true}
      headerProps={{
        title: 'Reset Password',
        subtitle:
          'Please complete the field below with your email address associated to your Faeth account',
        showBack: false,
        showLogo: true,
        centeredHeader: true,
      }}
    >
      <ForgotPasswordV2 />
    </BasePage>
  );
};
