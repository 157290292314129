"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubjectIdPrefix = exports.isMissingOnboardingCall = exports.ACTIVE_SUBJECT_STATUS = void 0;
var graphql_api_admin_1 = require("@fdha/graphql-api-admin");
exports.ACTIVE_SUBJECT_STATUS = [
    graphql_api_admin_1.SubjectStatus.Ongoing,
    graphql_api_admin_1.SubjectStatus.Screening,
    graphql_api_admin_1.SubjectStatus.CoachApproved,
];
/**
 * Returns true if the subject is missing an onboarding call.
 * @param status status of the subject
 * @param onboardingCallNeeded if the onboarding call is needed
 * @param onboardingCallScheduled if the onboarding call is scheduled
 * @returns true if onboarding call is needed and not scheduled
 */
var isMissingOnboardingCall = function (status, onboardingCallNeeded, onboardingCallScheduled) {
    return (!!status &&
        exports.ACTIVE_SUBJECT_STATUS.includes(status) &&
        onboardingCallNeeded === true &&
        onboardingCallScheduled === false);
};
exports.isMissingOnboardingCall = isMissingOnboardingCall;
/**
 * Generates a subject ID prefix based on the given template and values.
 * The `$subjectNumber` placeholder is ignored and replaced with an empty string.
 * @param trial - Abbreviation for the trial.
 * @param site - Identification for the site.
 * @param template - Template string containing placeholders (e.g., "$trial-$site-$subjectNumber").
 * @returns The generated prefix string with placeholders replaced.
 */
var getSubjectIdPrefix = function (trial, site, template) {
    if (!template) {
        return '';
    }
    var variables = {
        trial: trial || '',
        site: site || '',
    };
    return template.replace(/\$(\w+)/g, function (_, key) {
        if (key === 'subjectNumber') {
            return '';
        }
        return variables[key];
    });
};
exports.getSubjectIdPrefix = getSubjectIdPrefix;
