export enum ScheduleFrequency {
  Biweekly = 'Biweekly',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Once = 'Once',
  Triweekly = 'Triweekly',
  Weekly = 'Weekly',
}

export enum WeekDay {
  Fri = 'Fri',
  Mon = 'Mon',
  Sat = 'Sat',
  Sun = 'Sun',
  Thu = 'Thu',
  Tue = 'Tue',
  Wed = 'Wed',
}

export enum ScheduleCompleteWithin {
  OneDay = 1,
  TwoDays = 2,
  ThreeDays = 3,
  OneWeek = 7,
  EndOfDay = 99,
}

export enum SurveyFrequency {
  Biweekly = 'Biweekly',
  Daily = 'Daily',
  Monthly = 'Monthly',
  Once = 'Once',
  Triweekly = 'Triweekly',
  Weekly = 'Weekly',
  DaysOnDiet = 'DaysOnDiet',
}

export const completeWithinLabelByValue: Record<number, string> = {
  1: '1 day later',
  2: '2 days later',
  3: '3 days later',
  7: '1 week later',
  99: 'End of day',
};

const defaultWithinOptions = [
  ScheduleCompleteWithin.OneDay,
  ScheduleCompleteWithin.TwoDays,
  ScheduleCompleteWithin.ThreeDays,
  ScheduleCompleteWithin.OneWeek,
  ScheduleCompleteWithin.EndOfDay,
];

export const completeWithinOptionsByFrequency: Record<
  SurveyFrequency,
  ScheduleCompleteWithin[]
> = {
  [SurveyFrequency.Once]: defaultWithinOptions,
  [SurveyFrequency.Daily]: [
    ScheduleCompleteWithin.OneDay,
    ScheduleCompleteWithin.EndOfDay,
  ],
  [SurveyFrequency.Weekly]: defaultWithinOptions,
  [SurveyFrequency.Biweekly]: defaultWithinOptions,
  [SurveyFrequency.Triweekly]: defaultWithinOptions,
  [SurveyFrequency.Monthly]: defaultWithinOptions,
  [SurveyFrequency.DaysOnDiet]: defaultWithinOptions,
};

export const defaultCompleteWithinOptionByFrequency: Record<
  SurveyFrequency,
  ScheduleCompleteWithin
> = {
  [SurveyFrequency.Once]: ScheduleCompleteWithin.OneDay,
  [SurveyFrequency.Daily]: ScheduleCompleteWithin.OneDay,
  [SurveyFrequency.Weekly]: ScheduleCompleteWithin.ThreeDays,
  [SurveyFrequency.Biweekly]: ScheduleCompleteWithin.ThreeDays,
  [SurveyFrequency.Triweekly]: ScheduleCompleteWithin.ThreeDays,
  [SurveyFrequency.Monthly]: ScheduleCompleteWithin.OneWeek,
  [SurveyFrequency.DaysOnDiet]: ScheduleCompleteWithin.ThreeDays,
};

export const surveyFrequencyLabelByValue: Record<SurveyFrequency, string> = {
  [SurveyFrequency.Once]: 'Once',
  [SurveyFrequency.Daily]: 'Daily',
  [SurveyFrequency.Weekly]: 'Weekly',
  [SurveyFrequency.Biweekly]: 'Biweekly',
  [SurveyFrequency.Monthly]: 'Monthly',
  [SurveyFrequency.Triweekly]: 'Triweekly',
  [SurveyFrequency.DaysOnDiet]: 'Days on Diet',
};
