import React, { FC } from 'react';
import { Box } from '@mui/material';

import { VariantType } from '../../types/mui';
import { OverflowText } from '../OverflowText/OverflowText';
import { ConditionalWrapper } from '../ConditionalWrapper/ConditionalWrapper';
import { ButtonUnstyled } from '../Button/ButtonUnstyled';

export interface UsernameProps {
  name: string;
  textVariant?: VariantType;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Username: FC<UsernameProps> = ({ name, textVariant, onClick }) => {
  return (
    <Box display="flex" alignItems="center">
      <ConditionalWrapper
        showWrapper={!!onClick}
        wrapper={(children) => (
          <ButtonUnstyled
            style={{ display: 'flex', alignItems: 'center' }}
            onClick={(event) => onClick && onClick(event)}
          >
            {children}
          </ButtonUnstyled>
        )}
      >
        <OverflowText label={name || ''} variant={textVariant}>
          {name}
        </OverflowText>
      </ConditionalWrapper>
    </Box>
  );
};
