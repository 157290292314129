import { subjectStatusLabelByValue } from '@fdha/common-utils';
import { SubjectStatus } from '@fdha/graphql-api-sitestaff';
import { Chip, ChipProps, SkeletonWrapper } from '@fdha/web-ui-library';
import { subjectStatusColorByValue } from '@utils';
import React, { useMemo } from 'react';

interface SubjectTrialStatusChipProps extends ChipProps {
  subjectStatus?: SubjectStatus | null;
  showSkeleton?: boolean;
}

const subjectStatusByValue = (subjectStatus?: SubjectStatus | null) => {
  const label = subjectStatus
    ? subjectStatusLabelByValue[subjectStatus]
    : 'error';
  const color = subjectStatus
    ? subjectStatusColorByValue[subjectStatus]
    : 'error';

  return { label, color };
};

export const SubjectTrialStatusChip = ({
  subjectStatus,
  showSkeleton = false,
  ...props
}: SubjectTrialStatusChipProps) => {
  const subjectStatusChipProps = useMemo(
    () => subjectStatusByValue(subjectStatus),
    [subjectStatus]
  );

  if (!showSkeleton && !subjectStatus) return null;

  return (
    <SkeletonWrapper isLoading={showSkeleton} fullWidth variant="rounded">
      <Chip
        label={subjectStatusChipProps.label}
        color={subjectStatusChipProps.color}
        data-testid="CHIP"
        variant="outlined"
        {...props}
      />
    </SkeletonWrapper>
  );
};
