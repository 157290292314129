import { fetchUserAttributes } from '@aws-amplify/auth';

export const getUserId = async (): Promise<string> => {
  const userAttributes = await fetchUserAttributes();

  const userId = userAttributes['custom:migrated_sub'] ?? userAttributes.sub;

  if (!userId) throw new Error('[getUserId] userId not found');

  return userId;
};
