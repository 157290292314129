import { Box, IconButton, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  format,
  isSameMonth,
  isSameYear,
  isToday,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
} from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import Icon from 'react-eva-icons';

import { Typography } from '../Typography/Typography';

export enum RangePeriod {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export interface DayRange {
  from: Date;
  to: Date;
}

export interface DateRangePickerProps {
  v2?: boolean;
  rangePeriod?: RangePeriod;
  startingDate: Date;
  onChange: (startDate: Date, endDate: Date) => void;
}

type Header = {
  text: string;
  i18nKey: string;
  i18nParams?: { [key: string]: string };
};

export const DateRangePicker: React.FC<DateRangePickerProps> = ({
  v2,
  rangePeriod = RangePeriod.DAY,
  startingDate,
  onChange,
}) => {
  const theme = useTheme();

  const weekStart = useMemo(() => startOfISOWeek(startingDate), [startingDate]);
  const weekEnd = useMemo(() => endOfISOWeek(startingDate), [startingDate]);

  const [daysOfWeek, setDaysOfWeek] = useState<Date[]>([]);

  useEffect(() => {
    if (rangePeriod === RangePeriod.WEEK) {
      const days: Date[] = [];

      for (let i = 0; i < 7; i++) {
        days.push(addDays(weekStart, i));
      }

      setDaysOfWeek(days);
    }
  }, [weekStart, rangePeriod]);

  // Header
  const getDayHeader = (): Header => {
    return {
      text: format(startingDate, 'MMMM dd, yyyy'),
      i18nKey: 'common:dateRangePicker.dayHeader',
      i18nParams: { date: startingDate.toISOString() },
    };
  };

  const getMonthHeader = (): Header => {
    return {
      text: format(startingDate, 'MMMM yyyy'),
      i18nKey: 'common:dateRangePicker.monthHeader',
      i18nParams: { date: startingDate.toISOString() },
    };
  };

  const getWeekHeader = (): Header => {
    const sameMonth = isSameMonth(weekStart, weekEnd);
    const sameYear = isSameYear(weekStart, weekEnd);

    let header: Header;

    if (!sameMonth && sameYear) {
      header = {
        text: `${format(weekStart, 'MMMM')}/${format(weekEnd, 'MMMM yyyy')}`,
        i18nKey: 'common:dateRangePicker.weekHeader.diffMonthSameYear',
        i18nParams: {
          dateStart: weekStart.toISOString(),
          dateEnd: weekEnd.toISOString(),
        },
      };
    } else if (!sameMonth && !sameYear) {
      header = {
        text: `${format(weekStart, 'MMMM yyyy')}/${format(
          weekEnd,
          'MMMM yyyy'
        )}`,
        i18nKey: 'common:dateRangePicker.weekHeader.diffMonthDiffYear',
        i18nParams: {
          dateStart: weekStart.toISOString(),
          dateEnd: weekEnd.toISOString(),
        },
      };
    } else {
      header = {
        text: format(weekStart, 'MMMM yyyy'),
        i18nKey: 'common:dateRangePicker.weekHeader.sameMonth',
        i18nParams: { date: weekStart.toISOString() },
      };
    }

    return header;
  };

  const getHeader = (): Header => {
    switch (rangePeriod) {
      case RangePeriod.DAY: {
        return getDayHeader();
      }
      case RangePeriod.WEEK: {
        return getWeekHeader();
      }
      case RangePeriod.MONTH: {
        return getMonthHeader();
      }
    }
  };

  const header = getHeader();

  // Updates

  const handleDayChange = (mode: 'add' | 'subtract') => {
    let newDate;

    if (mode === 'add') {
      newDate = addDays(startingDate, 1);
    } else {
      newDate = addDays(startingDate, -1);
    }

    const start = startOfDay(newDate);
    const end = endOfDay(newDate);

    onChange(start, end);
  };

  const handleMonthChange = (mode: 'add' | 'subtract') => {
    let newDate;

    if (mode === 'add') {
      newDate = addMonths(startingDate, 1);
    } else {
      newDate = addMonths(startingDate, -1);
    }

    const start = startOfMonth(newDate);
    const end = endOfMonth(newDate);

    onChange(start, end);
  };

  const handleWeekChange = (mode: 'add' | 'subtract') => {
    let newDate;

    if (mode === 'add') {
      newDate = addWeeks(weekStart, 1);
    } else {
      newDate = addWeeks(weekStart, -1);
    }

    const start = startOfISOWeek(newDate);
    const end = endOfISOWeek(newDate);
    onChange(start, end);
  };

  const handleChange = (mode: 'add' | 'subtract') => {
    switch (rangePeriod) {
      case RangePeriod.DAY: {
        return handleDayChange(mode);
      }
      case RangePeriod.WEEK: {
        return handleWeekChange(mode);
      }
      case RangePeriod.MONTH: {
        return handleMonthChange(mode);
      }
    }
  };

  /************ V2 updates *********************/

  const headerVariant = v2 ? 'h5' : 'subtitle2';
  const weekDayVariant = v2 ? 'subtitle2' : 'caption';

  /*********************************************/

  const renderDatesOfWeek = () => {
    return (
      <Box
        display="flex"
        flexDirection="row"
        width="100%"
        alignItems="center"
        justifyContent="space-around"
        paddingTop={1}
      >
        {daysOfWeek.map((day) => renderDay(day))}
      </Box>
    );
  };

  const renderDay = (day: Date) => {
    const today = isToday(day);

    const selectedDayStyle = today
      ? {
          borderRadius: 50,
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
        }
      : {};

    const dayText = format(day, 'EEE');

    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        key={day.toString()}
      >
        <Typography
          variant={weekDayVariant}
          color={theme.palette.text.secondary}
          i18nKey={`common:frequency.weekDays.upper.${dayText.toLocaleLowerCase()}`}
        >
          {dayText}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            width: 32,
            height: 32,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ...selectedDayStyle,
          }}
        >
          {format(day, 'dd')}
        </Typography>
      </Box>
    );
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <IconButton
        sx={{ alignSelf: 'flex-end' }}
        onClick={() => handleChange('subtract')}
      >
        <Icon name="arrow-ios-back-outline" size="large" fill={grey[800]} />
      </IconButton>
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant={headerVariant}
          i18nKey={header.i18nKey}
          i18nParams={header.i18nParams}
        >
          {header.text}
        </Typography>
        {rangePeriod === RangePeriod.WEEK && renderDatesOfWeek()}
      </Box>
      <IconButton
        sx={{ alignSelf: 'flex-end' }}
        onClick={() => handleChange('add')}
      >
        <Icon name="arrow-ios-forward-outline" size="large" fill={grey[800]} />
      </IconButton>
    </Box>
  );
};
