import {
  Box,
  LinearProgress as MuiLinearProgress,
  LinearProgressProps as MuiLinearProgressProps,
  useTheme,
} from '@mui/material';
import React, { useMemo } from 'react';

import { Typography } from '../Typography/Typography';

export type ProgressType = 'steps' | 'percentage' | 'noLabel';

interface BaseProps extends MuiLinearProgressProps {
  type?: ProgressType;
  completed: number;
  total?: number;
}

interface LinearProgressBase extends BaseProps {
  type?: 'percentage' | 'noLabel';
}

interface LinearProgressSteps extends BaseProps {
  type?: 'steps';
  total: number;
}

export type LinearProgressProps = LinearProgressBase | LinearProgressSteps;

export const LinearProgress: React.FC<LinearProgressProps> = (props) => {
  const theme = useTheme();

  const { completed, type, total, ...muiProps } = props;

  const getLabel = () => {
    if (type === 'percentage') {
      return `${Math.round(completed)}%`;
    } else if (type === 'steps') {
      return `${completed}/${total}`;
    }
  };

  const percentage = useMemo(() => {
    if (type === 'steps') {
      return (completed / total) * 100;
    }
    return completed;
  }, [completed, total, type]);

  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <MuiLinearProgress
          variant="determinate"
          color="success"
          value={percentage}
          {...muiProps}
        />
      </Box>
      {type !== 'noLabel' && type !== undefined && (
        <Typography
          variant="body2"
          color={theme.palette.text.secondary}
          data-testid="PROGRESS_BAR_LABEL"
        >
          {getLabel()}
        </Typography>
      )}
    </Box>
  );
};
