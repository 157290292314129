"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.addOtpValidation = exports.addPhoneNumberValidation = exports.addAtLeastOneKeyValidation = void 0;
var Yup = __importStar(require("yup"));
var errorHandler_1 = require("../errorHandler");
var phoneRegExp = /^\d{3}-\d{3}-\d{4}$/g;
var getErrorMessage = function (key, validationConfig) {
    var hideErrorMessage = validationConfig.hideErrorMessage, errorMessage = validationConfig.errorMessage, systemType = validationConfig.systemType, translate = validationConfig.translate;
    if (hideErrorMessage) {
        // Return empty string because undefined fallbacks to Yup default error message
        return '';
    }
    if (errorMessage) {
        return errorMessage;
    }
    if (translate) {
        return (0, errorHandler_1.getTranslatedErrorMessage)(key, systemType);
    }
    else {
        return (0, errorHandler_1.getErrorMessage)(key);
    }
};
/**
 * Returns a validation schema for an object in which at least one key must have a value
 * @param config - The configuration for the validation
 * @returns Validation schema for an object in which at least one key must have a value
 */
var addAtLeastOneKeyValidation = function (config) {
    var message = getErrorMessage('atLeastOneKey', config);
    return Yup.object().test('at-least-one-key', message, function (value) {
        return Object.values(value).some(function (value) {
            return Array.isArray(value)
                ? value.length > 0
                : value !== null && value !== '' && value !== undefined;
        });
    });
};
exports.addAtLeastOneKeyValidation = addAtLeastOneKeyValidation;
/**
 * Returns a validation schema for a phone number
 * @param systemType - The system type to get the error message for
 * @param translate - Whether to translate the error message
 * @param errorMessage - The error message to display
 * @returns Validation schema for a phone number
 */
var addPhoneNumberValidation = function (config) {
    var message = getErrorMessage('validPhone', config);
    return Yup.string()
        .trim()
        .test('is-valid-phone', message, function (value) {
        if (!value)
            return false;
        var match = value.match(phoneRegExp);
        return !!match;
    });
};
exports.addPhoneNumberValidation = addPhoneNumberValidation;
/**
 * Returns a validation schema for an OTP
 * @param systemType - The system type to get the error message for
 * @param translate - Whether to translate the error message
 * @param errorMessage - The error message to display
 * @returns Validation schema for an OTP
 */
var addOtpValidation = function (config) {
    var message = getErrorMessage('validOtp', config);
    return Yup.string()
        .trim()
        .test('is-valid-otp', message, function (value) {
        var match = value === null || value === void 0 ? void 0 : value.match(/^\d{6}$/);
        return !!match;
    });
};
exports.addOtpValidation = addOtpValidation;
