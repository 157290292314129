import React from 'react';
import { BasePage } from '@components';
import { AuthStatus, ResetPasswordV2, useAuthStatus } from '@fdha/web-auth';
import { useLocation } from 'react-router';

interface StateProps {
  email: string;
  obscuredEmail: string;
}

export const ResetPassword = () => {
  const location = useLocation();
  const state = location.state as StateProps;
  const email =
    state?.obscuredEmail ||
    'your email address associated to your Faeth account';
  const authStatus = useAuthStatus();
  const showLogo = authStatus === AuthStatus.SIGNED_OUT;

  return (
    <BasePage
      type="default"
      contentSize="xsmall"
      verticallyCentered={true}
      headerProps={{
        title: 'We sent you a code via email',
        subtitle: `Please enter the 6 digit code sent to ${email}`,
        centeredHeader: true,
        showBack: false,
        showLogo,
      }}
    >
      <ResetPasswordV2 email={state?.email} />
    </BasePage>
  );
};
