import { BasePage } from '@components';
import { ChangePasswordV2 } from '@fdha/web-auth';
import React, { FC } from 'react';

interface ChangePasswordProps {
  termsOfServiceLink: string;
  privacyPolicyLink: string;
}

export const ChangePassword: FC<ChangePasswordProps> = ({
  termsOfServiceLink,
  privacyPolicyLink,
}) => {
  return (
    <BasePage
      type="default"
      contentSize="xsmall"
      verticallyCentered={true}
      headerProps={{
        title: 'Set Password',
        showBack: false,
        showLogo: true,
        centeredHeader: true,
      }}
    >
      <ChangePasswordV2
        showTosToggle
        termsOfServiceLink={termsOfServiceLink}
        privacyPolicyLink={privacyPolicyLink}
      />
    </BasePage>
  );
};
