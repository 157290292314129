import React from 'react';

import TextField, { TextFieldProps } from './TextField/TextField';
import {
  MaskedTextField,
  MaskedTextFieldProps,
} from './MaskedTextField/MaskedTextField';
import PasswordTextField, {
  PasswordTextFieldProps,
} from './TextField/PasswordTextField';
import { Select, SelectProps } from './Select/Select';
import { Autocomplete, AutocompleteProps } from './Autocomplete/Autocomplete';
import CircularProgress, {
  CircularProgressProps,
} from './CircularProgress/CircularProgress';
import {
  CheckboxGroup,
  CheckboxGroupProps,
} from './CheckboxGroup/CheckboxGroup';
import { RadioGroup, RadioGroupProps } from './RadioGroup/RadioGroup';
import {
  FrequencyInput,
  FrequencyInputProps,
} from './FrequencyInput/FrequencyInput';
import { TimeInput, TimeInputProps } from './TimeInput/TimeInput';
import Accordion, { AccordionProps } from './Accordion/Accordion';
import {
  DecimalTextField,
  DecimalTextFieldProps,
} from './DecimalTextField/DecimalTextField';
import CommentBox, { CommentBoxProps } from './CommentBox/CommentBox';
import { CommentCard, CommentCardProps } from './Post/Comment';
import AddOrEditPostCard, {
  AddOrEditPostCardProps,
} from './AddOrEditPostCard/AddOrEditPostCard';
import { DatePicker, DatePickerProps } from './DatePicker/DatePicker';
import { Post, PostProps } from './Post/Post';
import { LabelBox, LabelBoxProps } from './LabelBox/LabelBox';
import {
  AddressFields,
  AddressFieldsProps,
} from './AddressFields/AddressFields';
import {
  RecipeAdditionalInformation,
  RecipeAdditionalInformationProps,
} from './RecipeAdditionalInformation/RecipeAdditionalInformation';
import { MenuSection, MenuSectionProps } from './MenuSection/MenuSection';
import { TagFilter, TagFilterProps } from './TagFilter/TagFilter';

// IMPORTANT: Wrappers only, any changes should be done in the component itself.

export const TextFieldV2: React.FC<TextFieldProps> = (props) => {
  return <TextField {...props} v2={true} />;
};

export const PostV2: React.FC<PostProps> = (props) => {
  return <Post {...props} v2={true} />;
};

export const AddOrEditPostCardV2: React.FC<AddOrEditPostCardProps> = (
  props
) => {
  return <AddOrEditPostCard {...props} v2={true} />;
};

export const CommentCardV2: React.FC<CommentCardProps> = (props) => {
  return <CommentCard {...props} v2={true} />;
};

export const CommentBoxV2: React.FC<CommentBoxProps> = (props) => {
  return <CommentBox {...props} v2={true} />;
};

export const AccordionV2: React.FC<AccordionProps> = (props) => {
  return <Accordion {...props} v2={true} />;
};

export const SelectV2: React.FC<SelectProps> = (props) => {
  return <Select {...props} v2={true} />;
};

export function AutocompleteV2<
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined
>(props: AutocompleteProps<Multiple, DisableClearable>) {
  return <Autocomplete {...props} v2={true} />;
}

export const CircularProgressV2: React.FC<CircularProgressProps> = (props) => {
  return <CircularProgress {...props} v2={true} />;
};

export const PasswordTextFieldV2: React.FC<PasswordTextFieldProps> = (
  props
) => {
  return <PasswordTextField {...props} v2={true} />;
};

export const MaskedTextFieldV2: React.FC<MaskedTextFieldProps> = (props) => {
  return <MaskedTextField {...props} v2={true} />;
};

export const CheckboxGroupV2: React.FC<CheckboxGroupProps> = (props) => {
  return <CheckboxGroup {...props} v2={true} />;
};

export const RadioGroupV2: React.FC<RadioGroupProps> = (props) => {
  return <RadioGroup {...props} v2={true} />;
};

export const FrequencyInputV2: React.FC<FrequencyInputProps> = (props) => {
  return <FrequencyInput {...props} v2={true} />;
};

export const TimeInputV2: React.FC<TimeInputProps> = (props) => {
  return <TimeInput {...props} v2={true} />;
};

export const DatePickerV2: React.FC<DatePickerProps> = (props) => {
  return <DatePicker {...props} v2={true} />;
};

export const DecimalTextFieldV2: React.FC<DecimalTextFieldProps> = (props) => {
  return <DecimalTextField {...props} v2={true} />;
};

export const LabelBoxV2: React.FC<LabelBoxProps> = (props) => {
  return <LabelBox hideMargin={true} {...props} v2={true} />;
};

export const AddressFieldsV2: React.FC<AddressFieldsProps> = (props) => {
  return <AddressFields {...props} v2={true} />;
};

export const RecipeAdditionalInformationV2: React.FC<
  RecipeAdditionalInformationProps
> = (props) => {
  return <RecipeAdditionalInformation {...props} v2={true} />;
};

export const MenuSectionV2: React.FC<MenuSectionProps> = (props) => {
  return <MenuSection {...props} v2={true} />;
};

export const TagFilterV2: React.FC<TagFilterProps> = (props) => {
  return <TagFilter {...props} v2={true} />;
};
