import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BasePage, SubjectTrialStatusChip } from '@components';
import {
  useGetPatientUserQuery,
  useGetAvailableTimesForOnboardingCallQuery,
  useGetOnboardingCallInfoQuery,
  useGetAvailableDatesForOnboardingCallLazyQuery,
} from '@fdha/graphql-api-sitestaff';
import {
  Box,
  Grid,
  Paper,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  useTheme,
} from '@mui/material';
import { useParams } from 'react-router';
import {
  Button,
  Typography,
  StaticDatePicker,
  Loader,
  Warning,
} from '@fdha/web-ui-library';
import { format } from 'date-fns';
import { format as formatWithTimezone } from 'date-fns-tz';
import { NetworkStatus } from '@apollo/client';
import pRetry from 'p-retry';

import { ConfirmOnboardingCallScheduling } from './ConfirmOnboardingCallScheduling';

const currentDate = new Date();
const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const OnboardingCallScheduling = () => {
  const params = useParams();
  const theme = useTheme();

  const { profileId } = params;

  const formatedTimezone = useMemo(() => {
    return formatWithTimezone(currentDate, "z ('GMT' XXX)", {
      timeZone: timezone,
    });
  }, []);

  const [date, setDate] = useState<string | undefined>();
  const [showRetry, setShowRetry] = useState(false);

  const [selectedTime, setSelectedTime] = useState<string | undefined>();

  const [hasConfirmedTimeSelection, setHasConfirmedTimeSelection] =
    useState(false);

  const { data: subjectData, loading: loadingPatient } = useGetPatientUserQuery(
    {
      variables: { patientId: profileId || '' },
    }
  );

  const subject = subjectData?.patientUser;

  const [
    getAvailableDatesForOnboardingCall,
    { data: datesData, networkStatus: dateNetworkStatus },
  ] = useGetAvailableDatesForOnboardingCallLazyQuery({
    variables: {
      patientId: profileId || '',
      timezone,
    },
    notifyOnNetworkStatusChange: true,
  });

  const loadingMonth = dateNetworkStatus === NetworkStatus.loading;
  const loadingMonthFetch = dateNetworkStatus === NetworkStatus.setVariables;
  const loadingDate = !datesData || loadingMonth || loadingPatient;

  const {
    data: timesData,
    loading: loadingTimes,
    refetch: refetchTimes,
  } = useGetAvailableTimesForOnboardingCallQuery({
    skip: !date,
    variables: {
      patientId: profileId || '',
      date: date ? format(new Date(date), 'yyyy-MM-dd') : '',
      timezone,
    },
  });

  const { data: onboardingCall } = useGetOnboardingCallInfoQuery({
    variables: { patientId: profileId || '' },
    skip: loadingDate,
  });

  const availableDates = datesData?.availableDatesForOnboardingCall || [];
  const availableTimes = timesData?.availableTimesForOnboardingCall || [];
  const onboardingCallInfo = onboardingCall?.onboardingCallInfo;

  const handleGetAvailableDatesForOnboardingCall = useCallback(async () => {
    const run = async () => {
      return await getAvailableDatesForOnboardingCall();
    };

    try {
      setShowRetry(false);
      await pRetry(run, { retries: 4 });
    } catch (error) {
      setShowRetry(true);
    }
  }, [getAvailableDatesForOnboardingCall]);

  useEffect(() => {
    handleGetAvailableDatesForOnboardingCall();
  }, [handleGetAvailableDatesForOnboardingCall]);

  const isDayAvailable = (day: string) => {
    const convertedDay = format(new Date(day), 'yyyy-MM-dd');
    return !availableDates.includes(convertedDay);
  };

  const handleDayChange = (day: string | null) => {
    if (day) {
      setDate(day);
      setSelectedTime(undefined);
    }
  };

  const renderItem = (time: string, index: number) => {
    const convertedTime = format(new Date(time), 'h:mm a');
    return (
      <Grid data-testid="ONBOARDING_TIME" item xs={3} md={12 / 5} key={index}>
        <ToggleButtonGroup
          value={selectedTime}
          fullWidth
          sx={{ height: '100%' }}
        >
          <ToggleButton
            data-testid="ONBOARDING_TIME_BUTTON"
            onClick={() => setSelectedTime(time)}
            value={time}
            sx={{
              borderRadius: '10px',
              paddingY: 3,
              border: `1px solid ${theme.palette.text.disabled}`,
              '&.Mui-selected': {
                backgroundColor: theme.palette.primary.background,
                '&:hover': {
                  backgroundColor: theme.palette.primary.background,
                },
              },
            }}
            fullWidth
            size="large"
          >
            {convertedTime}
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    );
  };

  const renderTimesLoading = () => {
    return [...Array(20)].map((_, index) => (
      <Grid item xs={3} md={12 / 5} key={index}>
        <Skeleton variant="rectangular" height={65} />
      </Grid>
    ));
  };

  return (
    <BasePage
      type="default"
      isLoading={loadingPatient}
      headerProps={{
        title: subject?.name || '',
        subtitle: subject?.subject_id || '',
      }}
      navigationProps={{ type: 'breadcrumb' }}
    >
      <Stack spacing={2} height="100%">
        <SubjectTrialStatusChip
          subjectStatus={subject?.subjectTrialStatus}
          showSkeleton={loadingPatient}
          sx={{ width: '100%' }}
          size="medium"
        />
        {!hasConfirmedTimeSelection ? (
          <Paper
            sx={{
              display: 'flex',
              flexDirection: 'column',
              paddingX: 4,
              paddingY: 3,
              flexGrow: 1,
              flexBasis: 0,
              minHeight: 525,
              overflow: 'hidden',
            }}
          >
            <Typography
              data-testid="ONBOARDING_CALL_SCHEDULING_TITLE"
              variant="h4"
            >
              Onboarding Part 1 call scheduling
            </Typography>
            <Typography
              variant="body2"
              mt={1}
              mb={3}
              color={theme.palette.text.secondary}
              data-testid="ONBOARDING_CALL_SCHEDULING_SUBTITLE"
            >
              A Faeth Nutrition Coach will onboard the subject on the trial.
            </Typography>
            {showRetry && (
              <Box mb={3}>
                <Warning
                  title="Coach Assignment"
                  text="We’ve had an issue while trying to assign a coach to this subject."
                  content={
                    <Button
                      variant="outlined"
                      sx={{ width: 'fit-content' }}
                      onClick={handleGetAvailableDatesForOnboardingCall}
                    >
                      Try Again
                    </Button>
                  }
                />
              </Box>
            )}
            <Box display="flex" height="100%" overflow="hidden">
              {loadingDate ? (
                <Skeleton variant="rectangular" height={333} width="55%" />
              ) : (
                <Box data-testid="ONBOARDING_CALL_SCHEDULING_CALENDAR">
                  <StaticDatePicker
                    onChange={handleDayChange}
                    shouldDisableDate={isDayAvailable}
                    disableHighlightToday
                    disablePast
                    views={['day']}
                    loading={loadingMonthFetch}
                    renderLoading={() => <Loader />}
                    defaultValue={date}
                  />
                </Box>
              )}
              <Box marginX={2} width="100%" height="100%" overflow="scroll">
                {date ? (
                  <>
                    <Typography variant="h6">
                      {format(new Date(date), 'EEEE, MMMM d')}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={theme.palette.text.secondary}
                      mt={3}
                      mb={1}
                      fontWeight="medium"
                    >
                      {`TIMEZONE: ${formatedTimezone}`}
                    </Typography>
                  </>
                ) : (
                  <Typography
                    data-testid="ONBOARDING_CALL_SCHEDULING_SELECT_DATE"
                    variant="h6"
                    showSkeleton={loadingDate}
                    mb={2}
                  >
                    Select a date to see the available times.
                  </Typography>
                )}
                <Grid container spacing={1}>
                  {loadingTimes || loadingDate
                    ? renderTimesLoading()
                    : availableTimes.map((time, index) =>
                        renderItem(time, index)
                      )}
                </Grid>
              </Box>
            </Box>
            <Button
              variant="contained"
              sx={{ alignSelf: 'flex-end' }}
              disabled={!selectedTime}
              endEvaIcon={{ name: 'arrow-forward-outline' }}
              onClick={() => setHasConfirmedTimeSelection(true)}
              data-testid="CONTINUE_BUTTON"
            >
              Continue
            </Button>
          </Paper>
        ) : (
          <>
            {onboardingCall && (
              <ConfirmOnboardingCallScheduling
                isLoading={loadingDate}
                patientId={profileId || ''}
                datetime={selectedTime || ''}
                timezone={timezone}
                type={onboardingCallInfo?.name || ''}
                callDuration={onboardingCallInfo?.duration || 0}
                setHasConfirmedTimeSelection={setHasConfirmedTimeSelection}
                refetchTimes={refetchTimes}
              />
            )}
          </>
        )}
      </Stack>
    </BasePage>
  );
};
