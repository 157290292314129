import React from 'react';

import { List } from '..';
import { LoadMore } from '../LoadMore/LoadMore';

export interface InfiniteScrollListProps<T> {
  items: T[];
  isLoading: boolean;
  isLoadingMore: boolean;
  disableRowGap?: boolean;
  renderEmptyState?: () => React.ReactNode;
  renderLoading?: () => React.ReactNode;
  renderItem: (item: T, index: number) => React.ReactNode;
  loadMore: () => void;
}

export function InfiniteScrollList<T>({
  items,
  isLoading,
  isLoadingMore,
  disableRowGap,
  renderItem,
  renderEmptyState,
  renderLoading,
  loadMore,
}: InfiniteScrollListProps<T>) {
  const cursor = items.length;

  return (
    <List
      items={items}
      isLoading={isLoading}
      disableRowGap={disableRowGap}
      renderItem={renderItem}
      renderEmptyState={renderEmptyState}
      renderLoading={renderLoading}
    >
      <LoadMore
        onLoadMore={loadMore}
        listLength={cursor}
        isLoadingMore={isLoadingMore}
      />
    </List>
  );
}
