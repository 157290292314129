import { findLast } from 'lodash';
import { signOut } from '@aws-amplify/auth';
import { Trial } from '@fdha/graphql-api-sitestaff';
import {
  AppBarDrawerProps,
  AppBar as AppBarLib,
  AppBarUserMenuProps,
  useSnackbar,
} from '@fdha/web-ui-library';
import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router';

interface AppBarProps {
  userName: string;
  trials?: Trial[];
}

export const AppBar: React.FC<AppBarProps> = ({ userName, trials }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { showSnackbarV2 } = useSnackbar();

  const handleLogout = useCallback(async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      showSnackbarV2({ message: 'Error to log out', severity: 'error' });
    }
  }, [navigate, showSnackbarV2]);

  const handleDrawerItemClick = useCallback(
    (value: string) => {
      navigate(value);
    },
    [navigate]
  );

  const drawerOptions: AppBarDrawerProps['options'] = useMemo(() => {
    const homeRoute = [
      {
        value: '/',
        label: 'Home',
        onClick: handleDrawerItemClick,
        activeIcon: 'home',
        inactiveIcon: 'home-outline',
        'data-testid': 'HOME_PAGE',
      },
    ];

    const trialRouteList = trials?.map((trial) => {
      const trialRoute = {
        value: `/trials/${trial.id}`,
        label: trial.protocol_number || '',
        onClick: handleDrawerItemClick,
        activeIcon: 'file-text',
        inactiveIcon: 'file-text-outline',
        'data-testid': `TRIAL_${trial.protocol_abbreviation}_PAGE`,
      };

      return trialRoute;
    });

    const trialRoutes = trialRouteList || [];

    return [...homeRoute, ...trialRoutes];
  }, [handleDrawerItemClick, trials]);

  const userMenuOptions: AppBarUserMenuProps['options'] = useMemo(() => {
    return [
      {
        value: 'changePassword',
        label: 'Change Password',
        handleClick: () => navigate('/change-password'),
      },
      {
        value: 'logout',
        label: 'Log Out',
        handleClick: handleLogout,
        iconEnd: 'log-out-outline',
      },
    ];
  }, [navigate, handleLogout]);

  const selectedDrawerOption = useMemo(() => {
    return (
      findLast(drawerOptions, (opt) => pathname.startsWith(opt.value))?.value ||
      '/'
    );
  }, [drawerOptions, pathname]);

  return (
    <AppBarLib
      hasTransparentBackground={true}
      drawerProps={{
        buttonText: 'Menu',
        logoUrl: '/images/study_hub_logo_small.png',
        options: drawerOptions,
        activeOption: selectedDrawerOption,
      }}
      logoProps={{
        url: '/images/study_hub_logo_small.png',
        onClick: () => navigate('/'),
      }}
      userMenuProps={{
        options: userMenuOptions,
        name: userName,
      }}
    />
  );
};
