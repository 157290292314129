import { useApolloClient } from '@apollo/client';
import {
  ActivityDetectorProvider,
  AnalyticsProvider,
} from '@fdha/common-hooks';
import { AuthStatus, useAuthStatus } from '@fdha/web-auth';
import { Loader, useDialog, useSnackbar } from '@fdha/web-ui-library';
import { Box } from '@mui/material';
import { Public, Private } from '@routes';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router';

function App() {
  const client = useApolloClient();
  const authStatus = useAuthStatus();
  const { closeDialog } = useDialog();
  const navigate = useNavigate();
  const { showSnackbarV2 } = useSnackbar();

  const onSignOut = useCallback(async () => {
    showSnackbarV2({
      severity: 'warning',
      message: 'Session expired. Please log in again.',
      closeOnClickOutside: true,
      closeAutomatically: false,
    });
    closeDialog();
    navigate('/', { replace: true });
  }, [showSnackbarV2, closeDialog, navigate]);

  useEffect(() => {
    const clearCache = async () => {
      try {
        await client.cache.reset();
      } catch (e) {
        console.log('Error cleaning cache', e);
      }
    };

    if (authStatus === AuthStatus.SIGNED_OUT) {
      clearCache();
    }
  }, [authStatus, client]);

  return (
    <Box height="100vh" display="flex" flexDirection="column">
      {authStatus === AuthStatus.LOADING ? (
        <Loader />
      ) : authStatus === AuthStatus.SIGNED_IN ? (
        <AnalyticsProvider>
          <ActivityDetectorProvider onSignOut={onSignOut}>
            <Private />
          </ActivityDetectorProvider>
        </AnalyticsProvider>
      ) : (
        <Public />
      )}
    </Box>
  );
}

export default App;
